/* eslint no-undef: 0 */
'use strict';

module.exports = {
    digitalDataPayloadSuccess: function () {
        $('body').on('addToCartPayload', function (customEvent, response) {
            if (typeof _satellite !== 'undefined') {
                var currentProductDetails = response.productInforDirectCall;
                if (response.cardObjectAddToCard) {
                    window.digitalData.cart = response.cardObjectAddToCard;
                    if (typeof cartType !== 'undefined') {
                        window.digitalData.cart.attributes.cartType = cartType;
                    } else {
                        window.digitalData.cart.attributes.cartType = 'mini';
                    }
                }

                if (currentProductDetails) {
                    _satellite.track('addToBag', currentProductDetails);
                }
            }
        });

        $('body').on('removeFromCartPayload', function (customEvent, response) {
            var cartData = response.updatedCartContent;
            if (window.digitalData.cart && cartData) {
                window.digitalData.cart = cartData;
            }
            var deletedProduct = response.deletedProduct;
            if (deletedProduct && typeof _satellite !== 'undefined') {
                _satellite.track('removeFromBag', {
                    productID: deletedProduct.productID,
                    sku: deletedProduct.sku,
                    quantity: deletedProduct.quantity,
                    size: deletedProduct.size,
                    color: deletedProduct.color
                });
            }
        });

        $('body').on('updateCartDetails', function (customEvent, cartData) {
            if (window.digitalData.cart && cartData) {
                window.digitalData.cart = cartData;
            }
        });

        $('body').on('applyPromoPayload', function (customEvent, response) {
            if (response.totals && typeof _satellite !== 'undefined') {
                window.digitalData.cart = response.cardObjectAddCoupon;
                var discounts = response.totals.discounts;
                if (discounts && discounts.length > 0) {
                    var appliedPromo = discounts[discounts.length - 1];
                    if (appliedPromo) {
                        _satellite.track('promoCodeAdded', {
                            promoCode: appliedPromo.couponCode,
                            promoType: appliedPromo.type,
                            promoValue: Math.abs(appliedPromo.couponPriceValue)
                        });
                    }
                }
            }
        });

        $('body').on('shippingMethodPayload', function (
            customEvent,
            shippingMethodData
        ) {
            var cartData = window.digitalData.cart;
            if (cartData && cartData.price) {
                cartData.price.shippingMethod =
                    shippingMethodData.shippingMethod;
                cartData.price.shipping = parseFloat(
                    shippingMethodData.shippingCost.replace(/[^0-9.]/g, '')
                );
            }
        });

        $('body').on('checkoutStagePayload', function (
            customEvent,
            checkoutData
        ) {
            if (digitalData.page && digitalData.page.pageInfo) {
                if (checkoutData.checkoutStage === 'shipping') {
                    digitalData.page.pageInfo.pageID = 'checkout-shipping';
                } else if (checkoutData.checkoutStage === 'payment') {
                    digitalData.page.pageInfo.pageID = 'checkout-billing';
                } else if (checkoutData.checkoutStage === 'placeOrder') {
                    digitalData.page.pageInfo.pageID = 'checkout-review';
                }
            }
        });

        $('body').on('createAccountPayload', function (customEvent, response) {
            if (typeof _satellite !== 'undefined') {
                var eventName = 'registerSuccess';
                var eventValue = {userID: response.userId};
                var searchParams = new URLSearchParams(window.location.search);
                if (searchParams.has('rurl')) {
                    eventName = 'registration-success';
                    eventValue.details = { source: 'sfl-create-account' };
                }
                _satellite.track(eventName, eventValue);
            }
        });

        $('body').on('filterInteraction', function (customEvent, filterData) {
            if (typeof _satellite !== 'undefined') {
                _satellite.track('filterInteraction', {
                    filterCategory: filterData.filterType,
                    filterValue: filterData.filterValue
                });
            }
        });

        $('body').on('variationAttributePayload', function (e, response) {
            var data = response.data;
            if (data && typeof _satellite !== 'undefined') {
                var digitalProductData = data.digitalProductData;
                if (digitalProductData && !$('.sfl-prod-edit-modal:visible').length) {
                    var $currentElement = $(document.activeElement);
                    if ($currentElement.hasClass('select-size')) {
                        _satellite.track('selectedSize', {
                            productID: digitalProductData.productID,
                            sku: digitalProductData.sku,
                            size: digitalProductData.size
                        });
                    } else if (
                        $currentElement.hasClass('swatch-circle-container')
                    ) {
                        _satellite.track('selectedColor', {
                            productID: digitalProductData.productID,
                            sku: digitalProductData.sku,
                            color: digitalProductData.color,
                            displayPrice: data.productPrice.displayPrice,
                            displayRegularPrice: data.productPrice.displayRegularPrice,
                            onSale: data.productPrice.onSale
                        });
                    }
                }
            }
        });
    }
};
