/* eslint no-undef: 0 */
'use strict';

module.exports = {
    digitalDataPayloadError: function () {
        $('body').on('applyPromoPayloadError', function (customEvent, response) {
            if (typeof _satellite !== 'undefined') {
                window.digitalData.page = {
                    error: {
                        errorType: 'promo code',
                        errorMessage: response.errorMessage
                    }
                };
                _satellite.track('error', {
                    errorType: 'promo code',
                    errorMessage: response.errorMessage
                });
            }
        });

        $('body').on('createAccountPayloadError', function (
            customEvent,
            response
        ) {
            if (typeof _satellite !== 'undefined') {
                var eventName = 'error';
                var eventValue = {
                    errorType: 'register',
                    errorMessage: response.errorMessage
                };
                var searchParams = new URLSearchParams(window.location.search);
                if (searchParams.has('rurl')) {
                    eventName = 'error-tracking';
                    eventValue.errorType = 'sfl-create-account';
                }
                _satellite.track(eventName, eventValue);
            }
        });

        $('body').on('loginPayloadError', function (customEvent, response) {
            if (typeof _satellite !== 'undefined') {
                _satellite.track('error', {
                    errorType: 'login',
                    errorMessage: response.errorMessage
                });
            }
        });

        $('body').on('addToCartPayloadError', function (customEvent, response) {
            if (typeof _satellite !== 'undefined') {
                _satellite.track('error', {
                    errorType: 'cart add',
                    errorMessage: response.errorMessage
                });
            }
        });

        $('body').on('cartPayloadError', function (customEvent, response) {
            if (typeof _satellite !== 'undefined') {
                _satellite.track('error', {
                    errorType: 'cart',
                    errorMessage: response.errorMessage
                });
            }
        });

        $('body').on('checkoutPayloadError', function (customEvent, response) {
            if (typeof _satellite !== 'undefined') {
                _satellite.track('error', {
                    errorType: 'checkout',
                    errorMessage: response.errorMessage
                });
            }
        });

        $('body').on('emailSignupPayloadError', function (
            customEvent,
            response
        ) {
            if (typeof _satellite !== 'undefined') {
                _satellite.track('error', {
                    errorType: 'emailSignup',
                    errorMessage: response.errorMessage
                });
            }
        });
    }
};
